export const menuItems = [
    {
        id: 1,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 2,
        label: 'menuitems.orders.text',
        icon: 'ri-shopping-bag-fill',
        link: '/orders',
        subItems: [
            {
                id: 3,
                label: 'menuitems.orders.all_orders',
                link: '/orders#allorders'
            },
            {
                id: 3,
                label: 'menuitems.orders.pending_orders',
                link: '/orders#pending'
            },
            {
                id: 4,
                label: 'menuitems.orders.shipped_orders',
                link: '/orders#shipped'
            },
            {
                id: 5,
                label: 'menuitems.orders.failed_orders',
                link: '/orders#failed'
            },
            {
                id: 6,
                label: 'menuitems.orders.unpaid_orders',
                link: '/orders#unpaid'
            },
            // {
            //     id: 6,
            //     label: 'menuitems.uielements.list.carousel',
            //     link: '/ui/carousel'
            // },
            // {
            //     id: 7,
            //     label: 'menuitems.uielements.list.dropdowns',
            //     link: '/ui/dropdowns'
            // },
            // {
            //     id: 8,
            //     label: 'menuitems.uielements.list.grid',
            //     link: '/ui/grid'
            // },
            // {
            //     id: 9,
            //     label: 'menuitems.uielements.list.images',
            //     link: '/ui/images'
            // },
            // {
            //     id: 10,
            //     label: 'menuitems.uielements.list.lightbox',
            //     link: '/ui/lightbox'
            // },
            // {
            //     id: 11,
            //     label: 'menuitems.uielements.list.modals',
            //     link: '/ui/modals'
            // },
            // {
            //     id: 12,
            //     label: 'menuitems.uielements.list.rangeslider',
            //     link: '/ui/rangeslider'
            // },
            // {
            //     id: 13,
            //     label: 'menuitems.uielements.list.sessiontimeout',
            //     link: '/ui/session-timeout'
            // },
            // {
            //     id: 14,
            //     label: 'menuitems.uielements.list.progressbar',
            //     link: '/ui/progressbar'
            // },
            // {
            //     id: 15,
            //     label: 'menuitems.uielements.list.sweetalert',
            //     link: '/ui/sweet-alert'
            // },
            // {
            //     id: 16,
            //     label: 'menuitems.uielements.list.tabs',
            //     link: '/ui/tabs-accordion'
            // },
            // {
            //     id: 17,
            //     label: 'menuitems.uielements.list.typography',
            //     link: '/ui/typography'
            // },
            // {
            //     id: 18,
            //     label: 'menuitems.uielements.list.video',
            //     link: '/ui/video'
            // },
            // {
            //     id: 19,
            //     label: 'menuitems.uielements.list.general',
            //     link: '/ui/general'
            // },
            // {
            //     id: 20,
            //     label: 'menuitems.uielements.list.rating',
            //     link: '/ui/rating'
            // },
            // {
            //     id: 21,
            //     label: 'menuitems.uielements.list.notifications',
            //     link: '/ui/notification'
            // }
        ]
    },
    {
        id: 22,
        label: 'menuitems.user.text',
        icon: 'ri-user-line',
        subItems: [
            {
                id: 23,
                label: 'menuitems.user.all_users',
                link: '/users'
            },
            // {
            //     id: 24,
            //     label: 'menuitems.chat.text',
            //     link: '/chat'
            // },
            // {
            //     id: 25,
            //     label: 'menuitems.email.text',
            //     subItems: [
            //         {
            //             id: 26,
            //             label: 'menuitems.email.list.inbox',
            //             link: '/email/inbox'
            //         },
            //         {
            //             id: 27,
            //             label: 'menuitems.email.list.reademail',
            //             link: '/email/read'
            //         }
            //     ]
            // },
            // {
            //     id: 28,
            //     label: 'menuitems.ecommerce.text',
            //     subItems: [
            //         {
            //             id: 29,
            //             label: 'menuitems.ecommerce.list.products',
            //             link: '/ecommerce/products'
            //         },
            //         {
            //             id: 30,
            //             label: 'menuitems.ecommerce.list.productdetail',
            //             link: '/ecommerce/product-detail'
            //         },
            //         {
            //             id: 31,
            //             label: 'menuitems.ecommerce.list.orders',
            //             link: '/ecommerce/orders'
            //         },
            //         {
            //             id: 32,
            //             label: 'menuitems.ecommerce.list.customers',
            //             link: '/ecommerce/customers'
            //         },
            //         {
            //             id: 33,
            //             label: 'menuitems.ecommerce.list.cart',
            //             link: '/ecommerce/cart'
            //         },
            //         {
            //             id: 34,
            //             label: 'menuitems.ecommerce.list.checkout',
            //             link: '/ecommerce/checkout'
            //         },
            //         {
            //             id: 35,
            //             label: 'menuitems.ecommerce.list.shops',
            //             link: '/ecommerce/shops'
            //         },
            //         {
            //             id: 36,
            //             label: 'menuitems.ecommerce.list.addproduct',
            //             link: '/ecommerce/add-product'
            //         }
            //     ]
            // },
            // {
            //     id: 37,
            //     label: 'menuitems.kanban.text',
            //     link: '/apps/kanban-board'
            // }
        ]
    },
    {
        id: 35,
        label: 'menuitems.intervention.text',
        icon: 'ri-stack-line',
        subItems: [
            {
                id: 16,
                label: 'menuitems.intervention.all_interventions',
                link: '/intervention'
            },
        ]
    },
    // {
    //     id: 37,
    //     label: 'menuitems.components.text',
    //     icon: 'ri-stack-line',
    //     subItems: [
    //         {
    //             id: 38,
    //             label: 'menuitems.forms.text',
    //             subItems: [
    //                 {
    //                     id: 39,
    //                     label: 'menuitems.forms.list.elements',
    //                     link: '/form/elements'
    //                 },
    //                 {
    //                     id: 40,
    //                     label: 'menuitems.forms.list.validation',
    //                     link: '/form/validation'
    //                 },
    //                 {
    //                     id: 41,
    //                     label: 'menuitems.forms.list.advanced',
    //                     link: '/form/advanced'
    //                 },
    //                 {
    //                     id: 42,
    //                     label: 'menuitems.forms.list.editor',
    //                     link: '/form/editor'
    //                 },
    //                 {
    //                     id: 43,
    //                     label: 'menuitems.forms.list.fileupload',
    //                     link: '/form/uploads'
    //                 },
    //                 {
    //                     id: 44,
    //                     label: 'menuitems.forms.list.wizard',
    //                     link: '/form/wizard'
    //                 },
    //                 {
    //                     id: 45,
    //                     label: 'menuitems.forms.list.mask',
    //                     link: '/form/mask'
    //                 }
    //             ]
    //         },
    //         {
    //             id: 46,
    //             label: 'menuitems.tables.text',
    //             subItems: [
    //                 {
    //                     id: 47,
    //                     label: 'menuitems.tables.list.basic',
    //                     link: '/tables/basic'
    //                 },
    //                 {
    //                     id: 48,
    //                     label: 'menuitems.tables.list.advanced',
    //                     link: '/tables/advanced'
    //                 },
    //                 // {
    //                 //     id: 49,
    //                 //     label: 'Responsive Table',
    //                 //     link: ''
    //                 // }
    //             ]
    //         },
    //         {
    //             id: 50,
    //             label: 'menuitems.charts.text',
    //             subItems: [
    //                 {
    //                     id: 51,
    //                     label: 'menuitems.charts.list.apex',
    //                     link: '/charts/apex'
    //                 },
    //                 {
    //                     id: 52,
    //                     label: 'menuitems.charts.list.chartjs',
    //                     link: '/charts/chartjs'
    //                 },
    //                 {
    //                     id: 52,
    //                     label: 'menuitems.charts.list.chartist',
    //                     link: '/charts/chartist'
    //                 },
    //                 {
    //                     id: 52,
    //                     label: 'menuitems.charts.list.echart',
    //                     link: '/charts/chartist'
    //                 },
    //             ]
    //         },
    //         {
    //             id: 53,
    //             label: 'menuitems.icons.text',
    //             subItems: [
    //                 {
    //                     id: 54,
    //                     label: 'menuitems.icons.list.remix',
    //                     link: '/icons/remix'
    //                 },
    //                 {
    //                     id: 55,
    //                     label: 'menuitems.icons.list.materialdesign',
    //                     link: '/icons/material-design'
    //                 },
    //                 {
    //                     id: 56,
    //                     label: 'menuitems.icons.list.dripicons',
    //                     link: '/icons/dripicons'
    //                 },
    //                 {
    //                     id: 57,
    //                     label: 'menuitems.icons.list.fontawesome',
    //                     link: '/icons/font-awesome'
    //                 }
    //             ]
    //         },
    //         {
    //             id: 58,
    //             label: 'menuitems.maps.text',
    //             subItems: [
    //                 {
    //                     id: 59,
    //                     label: 'menuitems.maps.list.googlemap',
    //                     link: '/maps/google'
    //                 },
    //                 {
    //                     id: 60,
    //                     label: 'menuitems.maps.list.leaflet',
    //                     link: '/maps/leaflet'
    //                 }
    //             ]
    //         }
    //     ]
    // },
    // {
    //     id: 61,
    //     label: 'menuitems.pages.text',
    //     icon: 'ri-file-copy-2-line',
    //     subItems: [
    //         {
    //             id: 62,
    //             label: 'menuitems.authentication.text',
    //             subItems: [
    //                 {
    //                     id: 63,
    //                     label: 'menuitems.authentication.list.login',
    //                     link: '/auth/login-1'
    //                 },
    //                 {
    //                     id: 64,
    //                     label: 'menuitems.authentication.list.register',
    //                     link: '/auth/register-1'
    //                 },
    //                 {
    //                     id: 65,
    //                     label: 'menuitems.authentication.list.recoverpwd',
    //                     link: '/auth/recoverpwd-1'
    //                 },
    //                 {
    //                     id: 66,
    //                     label: 'menuitems.authentication.list.lockscreen',
    //                     link: '/auth/lock-screen-1'
    //                 },
    //             ]
    //         },
    //         {
    //             id: 67,
    //             label: 'menuitems.utility.text',
    //             subItems: [
    //                 {
    //                     id: 68,
    //                     label: 'menuitems.utility.list.starter',
    //                     link: '/pages/starter'
    //                 },
    //                 {
    //                     id: 69,
    //                     label: 'menuitems.utility.list.maintenance',
    //                     link: '/pages/maintenance'
    //                 },
    //                 {
    //                     id: 70,
    //                     label: 'menuitems.utility.list.comingsoon',
    //                     link: '/pages/coming-soon'
    //                 },
    //                 {
    //                     id: 71,
    //                     label: 'menuitems.utility.list.timeline',
    //                     link: '/pages/timeline'
    //                 },
    //                 {
    //                     id: 72,
    //                     label: 'menuitems.utility.list.faqs',
    //                     link: '/pages/faqs'
    //                 },
    //                 {
    //                     id: 73,
    //                     label: 'menuitems.utility.list.pricing',
    //                     link: '/pages/pricing'
    //                 },
    //                 {
    //                     id: 74,
    //                     label: 'menuitems.utility.list.error404',
    //                     link: '/pages/error-404'
    //                 },
    //                 {
    //                     id: 75,
    //                     label: 'menuitems.utility.list.error500',
    //                     link: '/pages/error-500'
    //                 }
    //             ]
    //         }
    //     ]
    // }
]